const baseURL = 'https://dev.io-academy.uk/apps/furniture-api';

export const categoriesURL  = baseURL + '/categories.php';
export const productsURL    = baseURL + '/products.php';
export const productURL     = baseURL + '/product.php';

export const currencySymbol = {
    'GBP': '&pound;',
    'USD': '$',
    'EUR': '&euro;',
    'YEN': '&yen;'
}
